document.addEventListener("DOMContentLoaded", () => {
  AOS.init();

  let copy = document.querySelector("#copy-wrapper");
  var rellax = new Rellax(".rellax", {
    callback: (position) => {
      copy.style.opacity = 1 / position;
    },
  });

  let scrollSnapContainer = document.querySelector('.scroll-snap-container');

  if (scrollSnapContainer) {
    scrollSnapContainer.addEventListener("scroll", () => {
      let sara = document.querySelector("#sara"); 
      sara.style.opacity = 1 - scrollSnapContainer.scrollTop / 1000;
      sara.style.transform = `scale(${1 - scrollSnapContainer.scrollTop / 13000})`;
      sara.style.filter = `blur(${scrollSnapContainer.scrollTop / 100}px)`;
    });

  }

  var elem = document.querySelector('.masonry');
  if (elem) {
    var msnry = new Masonry( elem, {
      itemSelector: '.grid-item',
      columnWidth: 1,
      gutter: 10,
      stagger: 30,
      percentPosition: true
    });
  }

  const menuIcon = document.querySelector("#hamburger");
  const mobileMenu = document.querySelector("#mobile-menu");
  
  const toggleMobileMenu = (e) => {
    if (menuIcon.classList.contains('toggled')) {
      menuIcon.classList.remove('toggled')
      mobileMenu.classList.remove('open')
    } else {
      menuIcon.classList.add('toggled')
      mobileMenu.classList.add('open')
    }
  }
  menuIcon.addEventListener('click', toggleMobileMenu);
  
  const themeIcon = document.querySelector("#theme-toggle");

  const toggleTheme = (e) => {
    if (document.body.classList.contains('dark')) {
      document.body.classList.remove('dark')
    } else {
      document.body.classList.add('dark')
    }
  }

  if (themeIcon) {
    themeIcon.addEventListener('click', toggleTheme);
  }

  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-VVSQZ63S5G');

});
